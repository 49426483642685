@import '../../sass/base';

.deactivate-account {
    display: block;
    text-align: center;
    font-family: Montserrat;

  .deactivate-account-body{
    margin: 0 40px;
    max-width: 275px;
  }
  .deactivate-account-title{
    font-size: 20px;
    line-height: 24px;
  }
  .deactivate-account-subtitle{
    font-size: 12px;
    line-height: 15px;
    margin: 10px 30px;
  }
  .deactivate-account-text{
    font-size: 12px;
    line-height: 14px;
    margin: 20px 10px 10px 10px;
    text-align: left;
  }

  .deactivate-account-footer{
    align-items: center;    
    padding-top: 30px;
    min-width: 280px;
  }
  .deactivate-account-cancel-button{
    border: 2px solid $primary-color;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 30px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    background-color: transparent;
    color: $white;
    margin: 10px;
  }
  .deactivate-account-confirm-button{
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    background: $primary-color;
    padding: 10px 30px;
    border-radius: 10px;
    border: none!important;
    margin: 10px;
  }
  button:disabled, button[disabled]{
    opacity: 0.3;
  }

}
