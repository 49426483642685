@import '../../sass/_variables.scss';

.user-ctas{
  .purple-button {
    background: $secondary-color;
  }

  .green-button, .isEditView {
    background: $primary-color;
  }

  .purple-button, .green-button, .cancel-button{
    position: relative;
    color: white;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 1.5rem;
    border: none;
    border-radius: 10px;
    top: 0.688rem;
    order: 0;
    flex-grow: 0;
    flex: none;
    font-style: normal;
    font-weight: bold;
    font-size: $button-font-size;
    font-family: $button-font-family;
    line-height: $button-line-height;
    @media (min-width: 992px) {
      width: 7.375rem;
      margin: 0px .5rem;
    }
  }
  .green-button{
    @media (min-width: 992px) {
      width: 9.5rem;
      margin: 0px .5rem;
    }
  }

  .cancel-button {
    background: inherit;
    border: 2px solid $primary-color;
  }

  .form-link{
    background-color: $light-dark-background-color;
    bottom: -50px;
    height: 60px;
    right: 10px;
    border-radius: 10px;
    width: calc(100% - 20px);
    @media (min-width: 992px) {
      bottom: -75px;
      width: 268px;
    }
    > div{
      background-color: $dark-gray-background-color;
      height: 40px;
      width: 100%;
      border-radius: 10px;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
      letter-spacing: 0px;
      padding-left: 5px;
      color: white;
      overflow: hidden;
      @media (min-width: 992px) {
        width: 248px;
      }

      span {
        font-weight: 400;
        display: inline-block;
        max-width: 220px;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (min-width: 992px) {
          max-width: 200px;
        }
      }
      .copy-icon, .refresh-icon{
        position: absolute;
        width: 21px;
        height: 28px;
        top: 15px;
        font-family: Font Awesome 5 Free;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: #78BE21;
        flex: none;
        order: 0;
        flex-grow: 0;
        @media (min-width: 992px) {
          height: 18px;
          width: 14px;
          font-size: 16px;
        }
      }
      .copy-icon{
        right: 70px;
        @media (min-width: 992px) {
          right: 36px;
        }
      }
      .refresh-icon{
        right: 20px;
        @media (min-width: 992px) {
          right: 18px;
        }
      }
    }
  }
}
  
.containerEdit {
  display: flex;
  align-content: center;
  justify-content: center;
  .isEditView {
    width: fit-content;
  }
}