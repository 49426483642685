@import '../../../sass/variables';

.footer-container{
    height: 15rem;
    border-top: solid $action-color 0.3rem;
    @media (min-width: 992px) {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    .footer-container-logo{
        width: max-content;
        .footer-logo{
            @media (min-width: 992px) {
                width: 10rem;
            }
            height: 2.33rem;
            margin-bottom: 1rem;
          }
        p{
            font-size: 0.8rem;
            color: rgba(255, 255, 255, 0.705)
        }
    }
    .footer-terms-policy{
        margin-right: 1rem;
        margin-bottom: 1rem;
        a{
            margin: 0.3rem;
            font-size: 0.8rem;
            font-weight: 600;
            color: #78BE21;
        }
    }
}
