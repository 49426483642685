@import '../../../sass/base';


.dealme-avatar-container {
  font-size: 0.75rem;
  padding: 5px;
  text-align: center;
  max-width: fit-content;
      
  @include white-primary-label;
  
  input[type="range"] {
    vertical-align: middle;
    margin: 5px;
    width: 75%;
  }
    
  button{
    position: static;
    color: white;
    width: 5rem;
    height: 40px;
    bottom: 0%;
    border: none;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: $button-font-size;
    font-family: $button-font-family;
    line-height: $button-line-height;
    background-color: $primary-color;
  }
}


