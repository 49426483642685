@import '../../sass/base';

.dealme-submission {
  overflow: hidden;

  .dealme-submission-left-side-container {
    width: 50vw;
    height: 100%;
    min-height: 100vh;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4.5rem;

    @include phone {
      margin: 3.125rem 0 3.125rem 0;
      min-width: 100vw;
      min-height: unset;
      flex-direction: column;
    }

    @include tablet {
      margin: 3.125rem 0 3.125rem 0;
      min-width: 100vw;
      min-height: unset;
      flex-direction: column;
    }

    .dealme-submission-left-side-container-logo, .dealme-submission-left-side-container-logo:hover  {
      cursor: pointer;
      display: none;

      @include phone {
        display: block;
      }

      @include tablet {
        display: block;
      }
    }

    .dealme-submission-left-side-container-cover-img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include phone {
        width: 12.5rem;
        height: 12.5rem;
        border-radius: 50%;
      }

      @include tablet {
        width: 12.5rem;
        height: 12.5rem;
        border-radius: 50%;
      }
    }
  }

  .dealme-submission-right-side-container {
    width: 50%;
    padding: 0;

    @include phone {
      width: 100%;
    }

    @include tablet {
      width: 100%;
    }

    svg {
      @include phone {
        width: 100%;
        margin:0;
      }

      @include tablet {
        width: 100%;
        margin: 0;
      }
    }
  }

  .dealme-submission-right-side-container-logo:hover {
    cursor: pointer;
  }

  .dealme-submission-right-side-inner-container {
    padding: 5.25rem 5.25rem 4.875rem 5.25rem;

    @include phone {
      padding: 1.25rem;
    }

    @include tablet {
      padding: 1.25rem;
    }

    .dealme-submission-right-side-inner-container-success-message-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 18rem;

      @include phone {
        margin-top: 0;
      }

      @include tablet {
        margin-top: 0;
      }

      svg {
        margin-bottom: 1.375rem;

        @include phone {
          display: block;
        }

        @include tablet {
          display: block;
        }
      }

      h2, p {
        color: white;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 1.75rem;
        text-align: center;
      }

      h2 {
        font-weight: bold;
      }

      p {
        font-weight: normal;
      }
    }
  }

  .dealme-submission-right-side-caption-container {
    padding: 0;
    margin: 3.125rem 0 4.5rem 0;
    color: white;
    text-align: left;
    position: relative;

    @include phone {
      margin: 2rem 0 1.875rem 0;
    }

    @include tablet {
      margin: 2rem 0 1.875rem 0;
    }

    h2 {
      margin: 0;
      font-weight: Bold;
      font-family: Montserrat, sans-serif;
      font-size: 1.5rem;
      line-height: 1.75rem;
      text-align: left;

      @include phone {
        font-weight: normal;
        line-height: 1.813rem;
      }

      @include tablet {
        font-weight: normal;
        line-height: 1.813rem;
      }
    }

    p {
      margin: 0.563rem 0 0 0;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.75rem;
      text-align: left;

      @include phone {
        font-size: 1rem;
        line-height: 1.25rem;
      }

      @include tablet {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }

  .dealme-submission-form-container {
    padding: 0;

    .dealme-submission-form-container-form {
      .dealme-submission-form-container-form-button {
        width: 100%;
        height: 40px;
        font-size: 0.875rem;
        line-height: 1.063rem;
        margin-top: 30px;
        text-align: center;

        @include primary-button;
        @media (min-width: 992px) {
          width: 114px;
        }
      }

      .form-group.col-md-6 {
        @include tablet {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }

      .row {
        margin: 0;
      }

      .form-group {
        padding-left: 0;
        padding-right: 1.875rem;
        margin-bottom: 1.25rem;

        @include phone {
          padding-right: 0;
        }

        @include tablet {
          padding-right: 0;
        }

        input.form-control {
          margin-right: -22px;

          @include dark-gray-bordered-input;
        }

        .dealme-submission-form-container-form-input-error-messageinput-error {
          @include input-with-error;
        }

        .dealme-submission-form-container-form-input-error-message {
          top: 0.625rem;
          line-height: 14px;
          position: relative;

          @include error-feedback-label;
        }

        textarea {
          @include dark-gray-bordered-textarea;
        }
      }

      .form-label {
        font-size: 12px;
        line-height: 14px;

        @include white-primary-label;
      }
    }
  }
  .form-control:focus {
    background: #455A64;
    color: white;
  }
}
select {
  display: inline-block;
  width: 80px;
  height: 40px;
  margin-left: 20px;
  font-size: 10px;
  border: none;
  color: white;
  border-radius: 0.625rem;
  background: #455A64;
  padding-left:0.5rem;
  padding-right: 0.5rem;  
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:-internal-autofill-selected {
  background: #455A64;
  color: white;
}