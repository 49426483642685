@import '../../sass/base';

.dealme-account-setting {
  h1{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.3px;
    color: #90A4AE;
  }
  .dropdown{
    display: contents;
    .dropdown-toggle::after {
      display: none;
    }
  }
  .dropdown-options{
    background: $light-dark-background-color;
    border-radius: 10px;
    Button{
      color: $white;
      border: none;
      font-size: 14px;
      line-height: 17px;
      background-color: transparent;
    }
  }
}
.dealme-account-setting-form-manager-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px;
  background: #78BE21!important;
  border-radius: 10px;
  border: none!important;
}
.dealme-account-setting-manager-button-options-container{
  margin-top:30px;
  display:flex;
  justify-content: space-between;
  margin-bottom: 30px;
  Button{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-transform: capitalize;
    padding: 10px 30px;
  }
}
.dealme-account-setting-manager-modal-hidden{
  display: none;
}
.dealme-account-setting-manager-modal{
  position:fixed;
  left: 50vw;
  top: 45vh;
  transform: translate(-313px, -40vh);
  z-index: 1009;
  background-color: $light-dark-background-color;
  border-radius: 30px;
  padding:30px;
  width: 626px;
  h2{
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.3px;
    color: $muted-text-color;
  }
}
.dealme-account-setting-manager-modal-title-wrapper{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  Button{
    border: none;
    background-color: transparent;
  }
}
.dealme-account-setting-form-input-avatar {
  margin-left: 0px!important;
  img{
    width: 90px;
    border-radius: 50%;
    &.not-setted {
      opacity: 0.5;
    }
  }
  label{
    width:max-content;
  }
}
.dealme-account-setting-form-edit-button, .dealme-account-setting-form-options-button, .dealme-account-setting-form-edit-camera-button, .dealme-account-setting-form-edit-button-phone{
  border: none!important;
  background-color: transparent!important;
  z-index: 100;
  padding: 0;
  img{
    border-radius: 0;
    width: 15px!important;
  }
}
.dealme-account-setting-form-edit-button, .dealme-account-setting-form-edit-camera-button, .dealme-account-setting-form-edit-button-phone{
  img{
    width: 15px!important;
  }
}
.dealme-account-setting-form-options-button{
  img{
    width: 5px!important;
  }
}
.dealme-account-setting-form-edit-button {
  img{
    width: 15px;
  }
}
.dealme-account-setting-form-edit-button-phone{
  margin-left: -20px;
}
.dealme-account-setting-wrapper{
  background-color: $dark-background-color-2;
  border-radius: 30px;
  margin-right: 30px;
  padding:20px;
  width: 100%;
}
.dealme-account-setting-form {
    display: flex;
    flex-flow: wrap;
    padding-right: 25px;
    .input-group {
      padding: 0;

      .input-group-append {
        align-items: center;
      }
    }

    .form-group {
      margin-bottom: 1.25rem;

      input.form-control {
        margin-right: -22px;

        @include dark-gray-bordered-input;
      }

      .dealme-account-setting-form-input-error {
        @include input-with-error;
      }

      svg {
        z-index: 999;
        right: 11px;
        position: relative;
      }

      svg:hover {
        cursor: pointer;
      }

      .dealme-account-setting-form-input-error-message {
        top: 0.625rem;
        line-height: 14px;
        position: relative;
        @include error-feedback-label;
      }
    }

    .dealme-account-setting-form-phone-form-group {
      .form-group {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 0;
        justify-content: space-between;

        .dealme-dark-gray-bordered-select__control {
          width: 100px;
        }

        .dealme-dark-gray-bordered-select__menu {
          width: 100px;
        }

        .dealme-dark-gray-bordered-select__single-value {
          color: white;
          font-size: 0.75rem;
          line-height: 0.875rem;
        }

        .dealme-dark-gray-bordered-select__input {
          color: white;
        }

        .dealme-dark-gray-bordered-select__option {
          font-size: 0.75rem;
          line-height: 0.875rem;
        }

        :nth-child(1) {
          padding-right: 0.313rem;
        }

        :nth-child(2) {
          margin-right: 0px;
        }
      }
    }

    .form-label {
      font-size: 12px;
      line-height: 14px;

      @include white-primary-label;
    }
  }
  .dealme-account-setting-form-edit-camera-button{
    position: absolute;
    top: 32px;
    left: 36px;
  }
  .dealme-account-setting-form{
    .form-control{
      border-top-right-radius: 10px!important;
      border-bottom-right-radius: 10px!important;
    }
  }
  .dealme-account-setting-form-bottom-inputs-container{
    // display:flex;
    flex-direction: column;
    width:100%;
  }
  .dealme-account-setting-form-input-edit-container{
    display: flex;
  }
  .dealme-account-setting-close-button{
    img{
      width: 15px;
    }
  }
.dealme-account-setting-overlay {
  width: 98vw;
  height: 100vh;
  position: fixed;
  z-index: 1007;
  background-color: rgba(18, 30, 36, 0.8);
}
.dealme-account-setting-overlay-hidden{
  display: none;
}
@media screen and (max-width: 991px) {
  .dealme-account-setting-overlay {
    top: -92px;
    right: -30px;
  }
  // .dealme-account-setting-manager-modal{
  //   width: 50%;
  //   margin-left: 25%;
  // }
}
@media screen and (max-width: 768px) {
  .dealme-account-setting-overlay {
    top: 0px;
  }
}

@media screen and (max-width: 559px) {
  .dealme-account-setting-manager-modal{
    position:absolute;
    left: 0;
    top: 0;
    transform: translate(1vw, 2vh);
    border-radius: 30px;
    width: 100vw;
    h2{
      font-size: 24px;
      letter-spacing: 0.3px;
    }
  }
  .dealme-account-setting-manager-button-options-container {
    flex-direction: column-reverse;
    h1{
      font-size: 28px;
      line-height: 37px;
      letter-spacing: 0.2px;
      color: #90A4AE;
      margin: 30px 0px 0px 0px;
    }
    .dropdown{
      button{
        width: 8vw;
      }
    }

    button{
      width: 70vw;
    }
  }
}

@media screen and (max-width: 425px) {
  .dealme-account-setting-manager-button-options-container{
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .dealme-account-setting-overlay {
    top: -92px;
    left: -290px;
  }
}
