@import '../../sass/base';

.forgot-password-new-password-form.container {
  position: relative;
  background-color: $light-dark-background-color;
  border-radius: 30px;
  padding: 0;

  @include phone {
    width: 100%;
    margin: auto;
    background: none;
  }

  .container {
    width: 100%;
    padding: 0;
    min-width: 240px;
  }

  .form-label {
    font-size: 0.75rem;
    line-height: 0.875rem;

    @include white-primary-label;
  }

  .forgot-password-new-password-form-form {
    padding: 3.125rem;

    @include phone {
      padding: 0;
    }

    .input-group {
      padding: 0;

      .input-group-append {
        align-items: center;
      }
    }

    .form-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin: 0 0 1.25rem 0;

      input.form-control {
        margin-right: -22px;

        @include dark-gray-bordered-input;
      }

      svg {
        z-index: 999;
        right: 11px;
        position: relative;
      }

      svg:hover {
        cursor: pointer;
      }

      .forgot-password-new-password-form-form-input-error {
        @include input-with-error;
      }

      .forgot-password-new-password-form-form-input-error-message {
        @include error-feedback-label;
      }
    }
  }

  .forgot-password-new-password-form-form-submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0 0 0;

    @include phone {
      margin: 1.25rem 0 0 0;
    }

    .container {
      padding: 0;
    }

    .btn-link {
      padding: 0;
      font-size: 0.75rem;
      line-height: 0.938rem;

      @include primary-link-button;
    }

    .forgot-password-new-password-form-form-submit-container-submit-button {
      width: 11.063rem;
      height: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.063rem;
      margin-bottom: 2.5rem;

      @include primary-button;

      @include phone {
        width: 100%;
        padding: 0;
      }
    }
  }
}
