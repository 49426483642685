@import '../../sass/base';

.dealme-home {
  width: 100%;
  color: $muted-text-color;
}

.footer {
  position: relative;
  // bottom: 0;
  // left: 0;
  z-index: 1010;
  background: #162228;
  height: 250px;
  width: 99vw;
  margin: 37vh auto auto 0;
  @media (min-width: 992px) {
    margin: 37vh auto auto -288px;
  }
}
