@import '../../sass/base';

.dealme-admin-users {
  h1{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.3px;
    color: #90A4AE;
    padding-left: 10px;
  }
  .btn{
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }  
}
.dealme-admin-users-form-manager-button{
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px!important;
  background: #78BE21!important;
  border-radius: 10px;
  border: none!important;
  img{
    padding-bottom: 3px;
  }
}
.dealme-admin-users-form-download-button{
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #7766BB!important;
  border-radius: 10px;
  border: none!important;
  margin: 0px 20px;
}

.dealme-admin-users-manager-button-options-container{
  margin:30px 8px 30px 0px;
  display:flex;
  justify-content: space-between;
}
.dealme-admin-users-manager-modal-hidden{
  display: none;
}
.dealme-admin-users-manager-modal{
  position:absolute;
  z-index: 1010;
  background-color: $light-dark-background-color;
  border-radius: 30px;
  padding:30px;
  
  h2{
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.3px;
    color: $muted-text-color;
  }
}
.dealme-admin-users-manager-modal-title-wrapper{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  Button{
    border: none;
    background-color: transparent;
  }
}

.dealme-admin-users-form{
  .form-control{
    border-top-right-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
  }
}

.dealme-admin-users-close-button{
  img{
    width: 15px;
  }
}

.dealme-admin-users-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1009;
  background-color: rgba(18, 30, 36, 0.8);
}
.dealme-admin-users-overlay-hidden{
  display: none;
}
@media screen and (min-width: 1025px) {
  .dealme-admin-users-overlay {
    top: -92px;
    right: -30px;
  }
  .dealme-admin-users-manager-modal{
    width: 50%;
    margin-left: 25%;
  }
}
@media screen and (max-width: 992px) {
  .dealme-admin-users-manager-button-options-container{
    margin:30px 18px 30px 0px;
  }
}
@media screen and (max-width: 768px) {
  .dealme-admin-users-overlay {
    top: 0px;
  }
}
@media screen and (max-width: 576px) {
  .dealme-admin-users-manager-modal{
    top: 0px;
  }
  .dealme-admin-users-manager-button-options-container{
    flex-direction: column;
    align-items: center;
    .buttons-admin{
      margin: 15px 0px auto auto;
    }
    .input-filter{
      width: 100%;
      padding: 0;
      input.form-control {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .dealme-admin-users-manager-modal{
    margin-left: 12vw;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .dealme-admin-users-overlay {
    top: -92px;
    left: -290px;
  }
}
