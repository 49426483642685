@import '../../sass/variables';

.landing {
  width: 100vw;
  overflow-x: hidden;
  @media (max-width: 339px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 200vw;
  }
  @media (min-width: 340px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 185vw;
  }
  @media (min-width: 375px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 170vw;
  }
  @media (min-width: 390px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 160vw;
  }
  @media (min-width: 420px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 150vw;
  }
  @media (min-width: 450px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 130vw;
  }
  @media (min-width: 475px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 105vw;
  }
  @media (min-width: 600px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 90vw;
  }
  @media (min-width: 700px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 70vw;
  }
  @media (min-width: 870px) {
    background-image:url('../../assets/Landing/violet-line.png');
    background-position: 0 60vw;
  }
  @media (min-width: 992px) {
    background-image: url('../../assets/Landing/hero.png');
    background-position: 0 140px;
  }
  background-repeat: no-repeat;
  background-size: contain;

  .landing-section-one-container {
    position: relative;
    width: 100%;
    margin-top: 45px;
    padding-top: 5vw;
    @media (min-width: 992px) {
      height: 60vw;
      margin-left: 10vw;
    }
  }

  .landing-section-two-container { 
    min-width: 98vw;
    height: 70rem;
    margin-left: 0;
    margin-right: 0;
    background-image:url('../../assets/Landing/cover.svg');
    background-repeat:no-repeat;
    background-size: cover;
  }

  .landing-section-one-left-container {
    width: 100vw;
    height: 366px;
    top: 3rem;
    @media (min-width: 992px) {
      width: 730px;
    }
    h1 {
      display: block;
      position: static;
      left: 0px;
      top: 0px;
      font-family: $text-font-family;
      font-style: normal;
      font-weight: 900;
      color: white; 
      font-size: 40px;
      line-height: 49px;
      @media (min-width: 992px) {
        font-size: $big-text-font-size;
        line-height: $big-text-line-height;           
      }
    }
    h4 {
      font-family: $text-font-family;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: white;
      margin-bottom: 32px;
      @media (min-width: 992px) {
        font-size: 20px;
      }
    }
    button { 
      position: static;
      color: white;
      min-width: 182px;
      height: 40px;
      padding: 10px 30px;
      border: none;
      border-radius: 10px;
      font-family: $text-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      background-color: $primary-color;
    }
  }
  .reference-row{
    display: flex;
    justify-content: center;
    width: 100vw;
    .landing-section-three-container {
      display: flex;
      width: 100vw;
      margin: 0;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .reference-tittle{
        width: 90%;
        margin-top: 4rem;
        font-size: 3.5rem;
        font-family: $text-font-family;
        font-style: normal;
        font-weight: 800;
        color: white;
        line-height: 3.5rem;
        
      }
      .reference-subTittle{
        width: 75%;
        font-size: 2.3rem;
        margin-bottom: 4.5rem;
        line-height: 2.8rem;
        font-family: $text-font-family;
        font-style: normal;
        color: white; 
      }
      .reference-container-cards{
        display: flex;
        justify-content: space-around;
      }
    }
  }
  .story-row{
    display: flex;
    justify-content: center;
    min-width: 100vw;
    width: 100vw;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    .landing-section-four-container {
      display: block;
      min-width: 100vw;
      width: 300vw;
      margin-left: 0;
      margin-right: 0;
      background-color: #455A64;
      padding-top: 50rem;
      padding-bottom: 6rem;
      position: relative;
      
      @media (min-width: 600px) {
        padding-top: 55rem;
      }

      @media (min-width: 770px) {
        padding-top: 60rem;
      }

      @media (min-width: 850px) {
        padding-top: 65rem;
      }

      @media (min-width: 992px) {
        padding-top: 30rem;
      }
      .selectors{
        display: flex;
        justify-content: center;
        margin:auto;
        margin-top: 4rem;
        @media (min-width: 992px) {
          margin-top: -1vw;
          margin-left: 9vw;
          justify-content: left;
        }
        div{
          input{
            width: 0.5rem;
            height: 0.5rem;
            margin: .5rem;
          }
          input[type='radio']:after {
            left: 0px;
            top: -9px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: relative;
            background: #121E24;
            content: '';
            display: inline-block;
            visibility: visible;
          }
      
          input[type='radio']:checked:after {
            left: 0px;
            top: -9px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: relative;
            background-color: $action-color;
            content: '';
            display: inline-block;
            visibility: visible;
          }
        }
      }
    }
  }
    .landing-section-five-container {
      min-width: 100vw;
    }

  .how, .brand, .instantly {
      margin-top: 150px;
      @media (min-width: 992px) {
        margin-top: 3rem;
      }
      h2 {
      text-align: center;
      color: white;
      font-family:  $text-font-family;
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 64px;
      @media (min-width: 992px) {
        font-size: 50px;
      }
    }
    .social-circle, .influenshe, .phone {
      width: 90vw;
      display: block;
      position: relative;
      margin: auto;
      margin-top: 50px;
      margin-bottom: 50px;
      @media (min-width: 992px) {
        display: inline;
        width: 27vw;
        top: -100px;
      }
      @media (min-width: 1440px) {
        width: 27vw;
      }
    }
    @media (min-width: 992px) {
    .influenshe{
      margin-left: 9vw;
      width: 35vw;
    }
    .social-circle, .phone {
      margin-left: 15vw;
    }
  }
    h3{
      color: white;
      display: inline-block;
      font-family:  $text-font-family;
      font-style: normal;
      font-weight: 900;
      text-align: center;
      margin-bottom: 20px;
      margin-left: 20px;
      margin-right: 20px;
      @media (min-width: 992px) {
        font-size: 24px;
        line-height: 34px;
        margin-left: 20px;
        width: 40vw;
        text-align: left;
        @media (min-width: 1440px) {
          width: 30vw;
        }
      }
    }
    h4{
      color: white;
      font-family: $text-font-family;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      font-size: 20px;
      line-height: 30px;
      margin-left: 20px;
      margin-right: 20px;
      @media (min-width: 992px) {
        max-width: 40vw;
        // margin-left: 60px;
        text-align: left;
      }
      @media (min-width: 1440px) {
        width: 30vw;
      }
    }
    .how-container, .brand-container, .instantly-container {
      display: inline-block;
      position: relative;
      @media (min-width: 992px) {
        width: 40vw;
        margin-left: 10vw;
      }
    }
    .instantly-container {
      padding-bottom: 80px;
    }
    .brand-container{
      @media (min-width: 992px) {
        margin-left: 15vw;;
      }
    }
  }
  .how {
    margin-top: 270px;
    @media (min-width: 992px) {
      margin-top: 3rem;
    }
    h3 {
      padding-top: 25px;
      @media (min-width: 992px) {
        padding-top: 160px;
      }
    }
  }
}
.number-cicle{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #EC407A;
  color: white;
  overflow: hidden;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  top: -20px;
  &.one{
    top: 0;
  }
  @media (min-width: 992px) {
    position: absolute;
    display: inline-flex;
    &.one{
      top: 150px;
      left: -50px;
    }
    &.two{
      top: -10px;
      left: -50px;
    }
    &.three{
      top: -10px;
      left: -50px;
    }
  }
}
.dont{
  padding: 20px;;
  padding-top: 9rem;
  padding-bottom: 9rem;
  background-color: #212D33;
  h2 {
    font-family: $text-font-family;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 64px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #FFFFFF;
    max-width: 1141px;
    margin-bottom: 70px;
    @media (min-width: 992px) {
      font-size: 50px;
    }
  }
  .dont-item {
    max-width: 310px;
    margin: auto;
    margin-top: 8rem;
    @media (min-width: 992px) {
      margin-top: 3rem;
      &.le {
        position: relative;
        left: 4vw
      }
      &.ri {
        position: relative;
        right: 4vw
      }
    }
    img {
      width: 80px;
      margin-left: 115px;
      margin-bottom: 30px;
    }
    h2 {
      font-style: normal;
      font-weight: 900;
      font-size: 40px;
      line-height: 64px;
      @media (min-width: 992px) {
        font-size: 50px;
      }
    }
    h3 {
      font-family: $text-font-family;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 34px;
      color: white;
      text-align: center;
      margin-bottom: 24px;
    }
    h4{
      font-family: $text-font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

.justify-content-even {
  justify-content: space-between;
  @media (min-width: 992px) {
    margin-top: 100px;
  }
}
.sign-up-container{
  background-color: #212D33;
  padding-top: 65px;
  padding-bottom: 75px;
  @media (min-width: 992px) {
    padding-top: 95px;
    padding-bottom: 95px;
  }
  h2{
    font-family: $text-font-family;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
    @media (min-width: 992px) {
      font-size: 50px;
    }
  }
    button { 
      color: white;
      min-width: 182px;
      height: 40px;
      padding: 10px 30px;
      border: none;
      border-radius: 10px;
      font-family: $text-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      background-color: $primary-color;
      margin: auto;
      margin-top: 42px;
      @media (min-width: 992px) {
        min-width: 150px;
      }
    }
}