@import '../../sass/base';

.filters-mobile {
  input, select {
    width: 100%;
    min-width: 100%;
    margin-right: 20px;
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    border: none;
    color: white;
    border-radius: 0.625rem;
    background: #455A64;
    height: 32px;
    &::-webkit-input-placeholder {
      font-size: 12px;
    }
    option {
      font-size: 18px;
    }
  }
  select {
    display: block;
  }
  label span{
    display: block;
    margin-bottom: 1rem;
    font-family: Montserrat;
    color: white;
    font-size: 12px;
    line-height: 14px
  }
  .form-control:focus {
    background: #455A64;
    color: white;
  }

  position: static;

}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #455A64 inset;
  color: white!important;
  transition: background-color 600000s 0s, color 600000s 0s;
}
input:-internal-autofill-selected {
  background: #455A64!important;
  color: white!important;
}

.filter-modal-opener{
  height: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #78BE21;
}