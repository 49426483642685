:root {
  --toastify-toast-width: 500px;
  --toastify-toast-min-height: 72px;
  --toastify-toast-max-height: 72px;
  --toastify-font-family: Montserrat, sans-serif;
  --toastify-color-success: #D4EDDA;
  --toastify-text-color-success: #155724;
}

.Toastify__toast-theme--colored {
  border-radius: 1.25rem;
}
