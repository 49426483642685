@import '../../../sass/variables';

.story-container{
    display: block;
    width: 100vw;
    transition: all 0.5s ease;
    position: absolute;
    top: 3rem;
    @media (min-width: 992px) {
        padding-left: 10vw;
    }
    .container-text{
        display: inline-block;
        text-align: center;
        @media (min-width: 992px) {
            background-image: url('../../../assets/Landing/quote.png');
            background-repeat: no-repeat;
            max-width: 58%;
            text-align: left;
            margin-top: 70px;
            padding-top: 30px;
            margin-left: -30px;
            padding-left: 30px;

        }
        .story-name{
            margin-top: 48px;
            font-family: $text-font-family;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 28px;
            color: #EC407A;
        }
        .story-text{
            font-family: $text-font-family;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #ECEFF1;
        }
        .story-role{
            font-family: $text-font-family;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #ECEFF1;
        }
    }
    img{
        border-radius: 50%;
        border: 10px solid #EC407A;

        &.desktop {
            width: 328px;
            height: 328px;
            margin-left: 6vw;
            margin-top: -20rem;
            @media (min-width: 1200px) {
                margin-left: 7vw;
            }
        }
        &.mobile{
            width: 80vw;
            height: 80vw;
            margin:auto;
            margin-bottom: 3rem;
        }
    }
}