@import '../../sass/variables';
@import '../../sass/base';

input[type="file"] {
    display: none;
}

.textcheck{
    font-weight: 900;
    color:white;
    margin: 0;
}

.gstart-container{
    min-width: 98vw;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    .gstart-logo{
        display: flex;
        justify-content: center;
        margin-top: 5rem;
        margin-bottom: 3rem;

        @include phone {
            display: grid;
            margin: 2rem;
        }
    }
    .gstart-title{
        display: flex;
        color: white;
        justify-content: center;
        font-size: 1.7rem;
        margin-right: 1rem;

        @include phone {
            display: grid;
            margin: 0px;
            font-size: 1.5rem;
        }
    }
    .gstart-checkcards{
        display: flex;
        justify-content: space-evenly;
        margin-top: 3rem;
        .gstart-card{
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 2rem;
            margin: 10px;
            height: 575px;
            width: calc(95%/3);
            max-width: 450px;
            min-width: 310px;
            border-radius: 6%;
            background-color: rgba(48, 57, 68, 0.746);
            .gstart-card-ciclecheck{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 2.7rem;
                width: 2.7rem;
                border-radius: 50%;
                background-color: $primary-color;
                flex-direction: column;
            }
            .gstart-card-cicleuncheck{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.7rem;
                height: 2.7rem;
                width: 2.7rem;
                border-radius: 50%;
                background-color: $action-color;
                flex-direction: column;
            }
            .gstart-card-content{
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 75%;
                margin-top: 1rem;
                margin-bottom: 1rem;
                img{
                    margin: 1rem;
                    border-radius: 50%;
                    width: 200px;
                    max-height: 200px;
                }
                .gstart-card-text-title{
                    font-size: 1.3rem;
                    margin-top: 0.5rem;
                    font-weight: 800;
                    text-align: center;
                    font-family: $text-font-family;
                    font-style: normal;
                    color: white; 
                }
                .gstart-card-text{
                    margin-top: 0.5rem;
                    // padding-inline: 0.5rem;
                    // padding-right: 0.5rem;
                    text-align: center;
                    font-size: 16px;
                    font-family: $text-font-family;
                    font-style: normal;
                    color: white; 
                }
                .gstart-card-content-video{
                    border-radius: 20px;
                    margin-top: 1rem;
                    max-width: 100%;
                    min-height: 57%;
                    overflow: hidden;
                    // box-shadow: 0 19px 51px 0 rgba(0,0,0,0.16), 0 14px 19px 0 rgba(0,0,0,0.07);
                }
            }
            button{
                position: static;
                color: white;
                width: 144px;
                height: 40px;
                bottom: 0%;
                border: none;
                border-radius: 10px;
                font-style: normal;
                font-weight: bold;
                font-size: $button-font-size;
                font-family: $button-font-family;
                line-height: $button-line-height;
                background-color: $primary-color;
            }
            .gstart-card-copy-url{
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                border-radius: 10px;
                width: 100%;
                background-color: $dark-gray-2-color;
                padding: 5px;
                p{
                    background-color: transparent;
                    border: none;
                    margin: 0;
                    overflow-x: scroll;
                    color: white;
                }
                p::-webkit-scrollbar{
                    height: 0.5rem;
                    border-radius: 0.5rem;
                }
                ::-webkit-scrollbar-track{
                    background-color: transparent;
                }
                ::-webkit-scrollbar-thumb{
                    height: 0.5rem;
                    background-color: $muted-text-color;
                    border-radius: 0.5rem; 
                }
                button{
                    position: static;
                    color: white;
                    margin-left: 0.5rem;
                    width: 5rem;
                    height: 40px;
                    bottom: 0%;
                    border: none;
                    border-radius: 10px;
                    font-style: normal;
                    font-weight: bold;
                    font-size: $button-font-size;
                    font-family: $button-font-family;
                    line-height: $button-line-height;
                    background-color: $primary-color;
                }
            }
            .gstart-card-getstarted{
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                justify-content: center;
                align-items: center;
                color: white;
                .gstart-card-getstarted-title{
                    font-size: 2.5rem;
                    font-weight: bold;
                    text-align: center;
                }
                .gstart-card-getstarted-arrow{
                    // font-size: 50px;
                    // font-weight: 900;
                    color: $primary-color;
                    width: 55px;
                    // -webkit-text-stroke: 0.5rem $primary-color
                }
            }
        }
    }
}
.gstart-footer{
    margin-top: 10rem;
}