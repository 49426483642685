.dealme-notifications h1{
    padding: 25px 0 50px 0;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.dealme-notifications{
    position: relative;
    display: flex;
    flex-direction: column!important;
    padding-left: 20px!important;
    padding-right: 20px!important;
    padding-bottom: 50px;
    
    min-height: 200px;
    background: #162228;
    z-index: 100000;
    .dealme-notifications-title-and-button-container{
        display: flex;
        justify-content: space-between;
    }
}
.dealme-notifications-title-and-close-container{
    display: flex;
    justify-content: space-between;
}
.dealme-notifications-close-button{
    margin-top:10px;
    height: 30px;
    background:transparent;
    border: none;
    padding: 0px!important;
}
.dealme-notifications-close-button button{
    padding: 0px!important;
}
.dealme-notifications-close-button img{
    margin-top: 20px;
    width: 17px!important;
    height: 17px!important;
}
.dealme-notifications-title-and-button-container h2{
    position: static;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.dealme-notifications-title-and-button-container button{
    background: #78BE21!important;
    padding: 0px 10px;
    position: static;
    height: 20px;
    box-shadow: 0px 2px 1px rgba(99, 60, 247, 0.15);
    border-radius: 10px;
    border:none;
    left: 260px;
    top: 0px;
    color: #FFFFFF!important;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
}

.dealme-notifications-empty-button{
    display: none;
}
.hide-dealme-notifications{
    display: none!important;
}

.dealme-notification-card.container {
    margin: 15px 0px 15px 0px!important;
}
.dealme-notifications-unread-notifications-container{
    margin-bottom: 20px;
}

@media screen and (max-width: 425px) {
    .dealme-notifications{
        width: 100vw!important;
    }
}
.dealme-notification-card.container{
    margin: 20px 0px!important;
}