@import '../../sass/base';

.dealme-navbar-menu {
  height: auto;
  width: 100%;
  min-width: 100vw;
  padding: 0;
  margin-bottom: 2rem;
  margin: 0!important;
  z-index: 1999;
  
  .nav-item {
    .container {
      padding: 0;
      margin: .5rem .5rem .5rem .5rem;
      display: flex;
      flex-direction: row;
      @media screen and (min-width: 992px) {
        margin: 2rem 2rem 0 0;
      }
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        cursor: pointer;

        margin-left: 20px;
        @media screen and (min-width: 992px) {
          width: 3.75rem;
          height: 3.75rem;
        }
      }

      svg {
        cursor: pointer;
      }

      .dealme-navbar-menu-profile-notification-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: nowrap;
        flex-direction: column;
        @media screen and (min-width: 992px) {
          margin: 0 1.375rem 0 0;
        }
      }

      .dealme-navbar-menu-profile-info-container {
        display: none;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: nowrap;
        flex-direction: column;
        @media screen and (min-width: 992px) {
          display: flex;
        }
        span {
          margin: 0;
          color: white;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 1.063rem;
          text-align: right;
          text-transform: capitalize;
        }

        p {
          margin: 0;
          color: white;
          font-style: normal;
          font-weight: bold;
          font-size: 0.625rem;
          line-height: 0.75rem;
          text-align: right;
          text-transform: capitalize;
        }
        // .logout-text {
        //   font-weight: 600;
        // }
        // .logout-text:hover {
        //   color: #0069d9;
        // }
      }

      .dropdown {
        @media screen and (min-width: 992px) {
          margin: 10px 10px 0px 0px;
        }
        .btn{
          background: transparent;
          border:none;
        }
        .dropdown-toggle::after {
          display: none;
        }
      }
      .dropdown-options{
        background: $light-dark-background-color;
        border-radius: 10px;
        Button{
          color: $white;
          font-weight: 600;
          border: none;
          font-size: 14px;
          line-height: 17px;
          background-color: transparent;
        }
        .logout-svg {
          // color: white;
          width: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    min-width: 95vw;
  }
}
.dealme-navbar-menu-hide-notifications{
  width: 0;
  height: 0;
  transition: all 0.2s ease;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
}
.dealme-navbar-menu-profile-notification-container button{
  background: transparent!important;
  border: none;
}
.dealme-navbar-menu-show-notifications dealme-notifications{
  display: flex!important;
}
.dealme-navbar-menu-show-notifications{
  transition: all 0.2s ease;
  background: #162228;
  height:100%;
  width: 425px;
  max-width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  z-index: 1999;
}
.dealme-notifications.container{
  margin: 0!important;
}
.shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1500;
  background-color: rgba(0, 0, 0, 0.75);
}

.logo{
  width: 100px;
  margin-top: 10px;
  @media screen and (min-width: 992px) {
    display: none;
  }
}