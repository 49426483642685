.view-as-manager{
  select {
    display: inline-block;
    width: 200px;
    height: 40px;
    margin-left: 20px;
    font-size: 12px;
    border: none;
    color: white;
    border-radius: 0.625rem;
    background: #455A64;
    padding-left:0.5rem;
    padding-right: 0.5rem;
    option {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin: 0px 10px;
      background: #455A64;
    }
    &:focus {
      background: #455A64;
      color: white;
    }
  }
}