@import '../_variables.scss';

:root {
  input:focus {
    box-shadow: none;
    border : 1px solid white !important;
    outline-color : white !important;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

@mixin error-feedback-label {
  top: 0.625rem;
  margin: 0;
  position: relative;
  line-height: 0.875rem;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: $red-alert-color;
  font-size: $input-feedback-font-size;
}

@mixin input-with-error {
  border: 1px solid $red-alert-color;
}

@mixin dark-gray-bordered-input {
  border: none;
  color: white;
  border-radius: 0.625rem;
  background: $dark-gray-background-color;
}

@mixin dark-gray-bordered-textarea {
  resize: none;
  @include dark-gray-bordered-input;
}