@import '../../sass/base'; 

.change-phone {

    .change-phone-form-phone {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 0;
      justify-content: space-between;

      .form-group {
        width: 300px;
        display: flex;
      }

      .dealme-dark-gray-bordered-select__control {
        width: 100px;
      }

      .dealme-dark-gray-bordered-select__value-container {
        min-width: 64px !important;
      }

      .dealme-dark-gray-bordered-select__indicator {
        min-width: 30px !important;
        :first-child{
          position: static;
        }
      }

      .dealme-dark-gray-bordered-select__menu {
        width: 100px;
        background: $dark-gray-2-color;
      }

      .dealme-dark-gray-bordered-select__single-value {
        color: white;
        font-size: 0.75rem;
        line-height: 0.875rem;
        min-width: 70px !important;
      }

      .dealme-dark-gray-bordered-select__input {
        color: white;
      }

      .dealme-dark-gray-bordered-select__option {
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: $light-dark-background-color;
      }

      // :nth-child(1) {
      //   padding-right: 0.313rem;
      // }

      :nth-child(2) {
        margin-right: 0px;
        text-align: left;
      }
    }

  .change-phone-form-submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0 0 0;

    .container {
      padding: 0;
    }

    .btn-link {
      padding: 0;
      font-size: 0.75rem;
      line-height: 0.938rem;

      @include primary-link-button;
    }
  }

    .change-phone-form-submit-container-submit-button {
      width: 11.063rem;
      height: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.063rem;
      margin-bottom: 2.5rem;

      @include primary-button;

      @include phone {
        width: 100%;
        padding: 0;
      }
    }

  // }
  
  .change-phone-form-input-error-message{
    top: 0.625rem;
    line-height: 14px;
    position: relative;
    @include error-feedback-label;
  }

  .change-phone-form-input-error {
    @include input-with-error;
  }


}
