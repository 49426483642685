@import '../../sass/base';

.dealme-not-found.container {
  padding-bottom: 3.125rem;

  .dealme-not-found-logo {
    width: 8.75rem;
    height: 2.043rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 6.25rem auto 9rem auto;
  }

  .dealme-not-found-logo:hover {
    cursor: pointer;
  }

  .dealme-not-found-caption-row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 3rem;

    h1 {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 5rem;
      line-height: 5.875rem;
      color: white;
      text-align: center;
    }

    p {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.75rem;
      color: $dark-gray-2-color;
      text-align: center;
    }
  }

  .dealme-not-found-cta-row {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;

    button:nth-child(1) {
      width: 8.063rem;
      height: 3.063rem;
      font-size: 0.875rem;
      line-height: 1.063rem;

      @include secondary-button;
    }

    button:nth-child(2) {
      width: 8.063rem;
      height: 3.063rem;
      font-size: 0.875rem;
      line-height: 1.063rem;

      @include primary-button;
    }
  }
}
