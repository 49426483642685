@import '~bootstrap/scss/bootstrap.scss';

@import '../sass/variables';
@import '../sass/mixins/_label_styles';
@import '../sass/mixins/_input_styles';
@import '../sass/mixins/_select_styles';
@import '../sass/mixins/_button_styles';
@import '../sass/mixins/responsive_mixins_bootstrap';

html, body {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: $dark-background-color;
  font-family: Montserrat, sans-serif;
}
