@import '../_variables.scss';

:root {
  .dealme-dark-gray-bordered-select__control {
    border: none;
    border-radius: 0.625rem;
    background: $dark-gray-background-color;
  }

  .dealme-dark-gray-bordered-select__indicator {
    color: white;
  }

  .dealme-dark-gray-bordered-select__indicator:hover {
    color: white;
  }

  .dealme-dark-gray-bordered-select__indicator-separator {
    display: none;
  }

  .dealme-dark-gray-bordered-select__single-value {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: white;
    min-width: 50px;
  }

  .dealme-dark-gray-bordered-select__value-container {
    min-width: 50px;
  }

  .dealme-dark-gray-bordered-select__option {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-align: left;
  }

}
