@mixin phone {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin large {
  @media only screen and (max-width: 1800px) {
    @content;
  }
}
