@import '../../sass/_animations.scss';
@import '../../sass/_sidebar_variables.scss';

@import '~react-pro-sidebar/dist/scss/styles.scss';

.dealme-sidebar {
  width: 100vw;
  height: 100%;
  position: absolute;
  @media (min-width: 992px) {
    width: auto;
  }

  .pro-sidebar{
    position: fixed;
  }
  .dealme-sidebar-header-container {
    padding: 1.25rem;
    display: flex;
    flex-direction: column;

    svg {
      cursor: pointer;
    }

    .row {
      margin: 0;
    }

    .row:nth-child(1) {
      align-self: flex-start;
      @media (min-width: 992px) {
        align-self: flex-end;
      }
    }

    .row:nth-child(2) {
      align-self: start;
      margin: 30px 0 0 0;
      @media (min-width: 992px) {
        align-self: center;
      }
    }
  }

  .pro-menu {
    .pro-menu-item.active {
      border-left: $primary-color 0.188rem solid;
    }
  }
  &.collapsed .dealme-sidebar-header-container .row:nth-child(1) {
    align-self: center;
  }

  @media (max-width: 991px) {
    width: 96vw;
  //  padding-left: 3vw;
  }

  .inMobileMenu{
    position: relative;
    top: -3rem;
    left: 2rem;
    img{
      border-radius: 50%;
      height: 64px;
      width: 64px;
      
    }
    h4 {
      font-family: $text-font-family;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 128.91%;
      display: flex;
      align-items: center;
      color: #FFFFFF;
    }
    p {
      font-family: $text-font-family;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 128.91%;
      display: flex;
      align-items: center;
      color: #FFFFFF;
          }
  }
}

.dealme-sidebar-children-container {
  width: 100%;
  @media (min-width: 992px) {
    position: absolute;
    left: 290px;
    width: calc(100% - 320px);
    transition: ease 0.2s;
    &.collapsed  {
      left: 100px;
      width: calc(100% - 130px);
      transition: ease 0.2s;
    }
  }
}
