@import '../../sass/_variables.scss';

.landing-header {
  width: 100%;
  height: 3.75rem;
  background: $dark-background-color;

  .landing-header-logo {
    top: 0.688rem;
    left: 1.875rem;
    width: 10rem;
    height: 2.33rem;
    position: absolute;
  }

  .collapse{
    justify-content: flex-end;
  }
  
  .landing-header-sign-container {
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    float: right;
  }

  .landing-header-sign-up-button {
    background: $secondary-color;
  }

  .landing-header-sign-in-button {
    background: $primary-color;
  }

  .landing-header-sign-up-button, .landing-header-sign-in-button {
    position: relative;
    width: 6.25rem;
    color: white;
    height: 2.5rem;
    margin: 0px .5rem;
    border: none;
    border-radius: 10px;
    top: 0.688rem;
    order: 0;
    flex-grow: 0;
    flex: none;
    font-style: normal;
    font-weight: bold;
    font-size: $button-font-size;
    font-family: $button-font-family;
    line-height: $button-line-height;
    @media (min-width: 992px) {
      width: 7.375rem;
    }
  }
}
