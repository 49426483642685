@import '../../sass/base';


.dealme-manager-list {
      
  @include white-primary-label;
  
  .dealme-manager-list-title {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.3px;
    color:$dark-gray-2-color;
    margin-block: 35px;
  }
  
  .dealme-manager-list-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    .dealme-manager-list-row{
      font-size: 14px;
      line-height: 17px;
      background: $dark-background-color-2;
      letter-spacing: 0.3px;
      height: 50px;
      overflow: hidden;
    }

    tr{
      height: 50px;
      &:first-child{
        cursor: pointer;
        #delete{
          cursor: auto;
        }
      }

      th{
        font-size: 12px;
        line-height: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        max-width: 15%;
        min-width: 114px;
      }
      td{
        // border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 15%;
        
        .dealme-manager-list-status-0{
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          background-color: $action-color;
          border-radius: 5px;
          height: 25px;
          width: 80px;
          padding: 5px 10px;
          text-align: center;
        }
        .dealme-manager-list-status-1{
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          background-color: $primary-color;
          border-radius: 5px;
          height: 25px;
          width: 88px;
          padding: 5px 10px;
          text-align: center;
        }
        .dealme-manager-list-status-2{
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          background-color: $yellow-color;
          border-radius: 5px;
          height: 25px;
          width: 88px;
          padding: 5px 10px;
          text-align: center;
        }
        .dealme-manager-list-close-button{
          img{
            width: 15px;
          }
          background: $dark-background-color-2;
          padding-inline: 10px;
          border: none;
        }
      }

      th:first-child {
        padding-left: 1rem;
      }
      td:first-child{
        padding-left: 1rem;
        border-radius: 10px 0 0 10px;
      }
      td:last-child{
        padding-right: 1rem;
        border-radius: 0 10px 10px 0;
      }

    }

  }

  .mobile-home{
    color: white;
  
    > div {
      border-radius: 10px;
      overflow: hidden;
  
      > div {
        height: 53px;
        border-bottom: solid 1px $muted-text-color;
        background-color: #212D33;
        height: 56px;
  
        &.read-status{
          height: 5px;
          background-color: $dark-gray-2-color;

          &.status-0{
            background-color: $action-color;
          }
          &.status-1{
            background-color: $primary-color;
          }
          &.status-2{
            background-color: $yellow-color;
          }
          &.status-3{
            background-color: $red-alert-color;
          }
        }

        button {
          background: $primary-color;
          border: none;
          border-radius: 10px;
          padding: 10px;
          width: 40px;
        }
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

}


