@import '../../sass/base';

.dealme-terms-of-use.container {
  padding: 0;
  padding-bottom: 3.125rem;

  .dealme-terms-of-use-logo {
    width: 8.75rem;
    height: 2.043rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 6.25rem auto 3.875rem auto;
  }

  .dealme-terms-of-use-logo:hover {
    cursor: pointer;
  }

  .dealme-terms-of-use-caption-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;

    h1 {
      color: white;
      font-size: 3.125rem;
      font-style: normal;
      font-weight: bold;
      font-family: Montserrat, sans-serif;
      line-height: 3.688rem;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .dealme-terms-of-use-content-row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin-top: 2.125rem;

    h4 {
      color: $action-color;
      font-weight: bold;
      font-family: Montserrat, sans-serif;
      margin-top: 1.875rem;
      text-align: left;
    }

    p {
      color: white;
      text-align: left;
      font-family: Montserrat, sans-serif;
    }
  }
}
