@import '../../sass/base';

.view-as{
  .form-group{
    width: 100%;
    position: relative;
    z-index:10;
  }
  input.form-control {
    width: 100%;
    min-width: 280px;
    margin-top:.5rem;
    border: none;
    color: white;
    border-radius: 0.625rem;
    background: #455A64;
    @media (min-width: 992px) {
      width: 18rem;
    }
  }
  .search-result {
    position: absolute;
    top: 3.5rem;
    padding: 1rem;
    border: 1px solid #455A64;
    border-radius: 0 0 0.625rem 0.625rem;
    background: #455A64;
    color: white;
    width: 100%;
    min-width: 280px;
    overflow-x: hidden;
    max-height: 100px;
    overflow-y: auto;
    @media (min-width: 992px) {
      width: 18rem;
    }
    .search-result-item {
      padding-bottom: .5rem;
      padding-top: .5rem;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      cursor: pointer;
    }
  }
}
.mini-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.mini-scrollbar::-webkit-scrollbar-track {
  background-color:#ccc;
}
.mini-scrollbar::-webkit-scrollbar-thumb{
  background-color:#777;
  border-radius:4px;
}
.mini-scrollbar::-webkit-scrollbar-thumb:hover{
  background-color:#777;
  border:1px solid #333333;
}
.mini-scrollbar::-webkit-scrollbar-thumb:active{
  background-color:#666;
  border:1px solid #333333;
}