@import '../../sass/base';

.dealme-signin {

  .container {
    width: 21.75rem;
  }

  @include phone {
    width: 100%;
    margin: 0;
    padding: 0 1.25rem 0 1.25rem;
  }

  .dealme-signin-logo {
    display: block;
    margin: 6.25rem auto 6.25rem auto;
  }

  .dealme-signin-logo:hover {
    cursor: pointer;
  }

  .dealme-signin-caption-container {
    position: relative;
    padding: 0 0 1.875rem 0;

    @include phone {
      width: 100%;
    }

    p {
      padding: 0;
      margin: 0;
      font-size: 1.875rem;
      line-height: 2.313rem;

      @include white-primary-label;
    }
  }

  .dealme-signin-form-container {
    position: relative;
    background-color: $light-dark-background-color;
    border-radius: 30px;
    padding: 0;

    @include phone {
      width: 100%;
      background: none;
    }

    .container {
      width: 100%;
      padding: 0;
    }

    .form-label {
      font-size: 0.75rem;
      line-height: 0.875rem;

      @include white-primary-label;
    }

    .dealme-signin-form {
      padding: 3.125rem;

      @include phone {
        padding: 0;
      }

      .input-group {
        padding: 0;

        .input-group-append {
          align-items: center;
        }
      }

      .form-group {
        margin: 0 0 1.25rem 0;

        input.form-control {
          margin-right: -22px;

          @include dark-gray-bordered-input;
        }

        .dealme-signin-form-input-error {
          @include input-with-error;
        }

        .dealme-signin-form-input-error-message {
          @include error-feedback-label;
        }

        svg {
          z-index: 999;
          right: 11px;
          position: relative;
        }

        svg:hover {
          cursor: pointer;
        }
      }
    }
  }

  .dealme-signin-form-submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0 0 0;

    @include phone {
      margin: 1.25rem 0 0 0;
      flex-direction: column-reverse;
    }

    .container {
      padding: 0;
    }

    .btn-link {
      padding: 0;
      font-size: 0.75rem;
      line-height: 0.938rem;

      @include primary-link-button;
    }

    .dealme-signin-form-submit-container-cta-container {
      width: 100%;
      display: flex;
      text-align: center;
      flex-direction: row;
      justify-content: space-between;
      margin: 2.5rem 0 0 0;

      @include phone {
        margin: 0;
      }
    }

    .dealme-signin-form-submit-container-submit-button {
      width: 6.93rem;
      height: 2.5rem;
      font-size: 0.875rem;
      line-height: 1.063rem;

      @include primary-button;

      @include phone {
        width: 100%;
        height: 3.75rem;
        padding: 1.25rem;
        margin: 3.75rem 0 3.75rem 0;
      }
    }
  }
}
