.dealme-notification-card{
    display:flex;
}
.dealme-notification-card-profile-image{
    margin-right: 20px;
    width: 40px!important;
    height: 40px!important;
}
.dealme-notification-card-text{
    margin-bottom: 10px;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #FFFFFF
}
.dealme-notification-card-button{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    color: #78BE21;
    cursor: pointer;
}
.dealme-notification-card-time{
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 150%;
    color: #90A4AE;
}
.dealme-notification-card-body{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.dealme-notification-card-buttons-and-time-container{
    display: flex;
    justify-content: space-between;
}
.dealme-notification-card-buttons-container{
    width: 60%;
    display: flex;
    justify-content: space-between;
}