@import '../../sass/base';

.dealme-forgot-password.container {
  width: 21.75rem;

  @include phone {
    width: 100%;
    margin: 0;
    padding: 0 1.25rem 0 1.25rem;
  }

  .dealme-forgot-password-logo {
    display: block;
    margin: 6.25rem auto 6.25rem auto;
  }

  .dealme-forgot-password-logo:hover {
    cursor: pointer;
  }

  .dealme-forgot-password-caption-container {
    position: relative;
    padding: 0 0 1.875rem 0;

    p {
      padding: 0;
      margin: 0;
      font-size: 1.875rem;
      line-height: 2.313rem;

      @include white-primary-label;
    }
  }
}
