@import '../../sass/base';

.stat-box {
  > div {
    background-color: $dark-background-color-2;
    height: 150px;
    border-radius: 10px;
    h3 {
      font-size: 18px;
      font-weight: 400;
    }
    h4{
      font-size: 50px;
      font-weight: 700;
    }
  }
}
