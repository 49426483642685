@import '../_variables.scss';

:root {
  button:hover {
    cursor: pointer;
  }
}

@mixin primary-button {
  border: none;
  border-radius: 0.625rem;
  background-color: $primary-color;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}

@mixin secondary-button {
  border: none;
  border-radius: 0.625rem;
  background-color: $secondary-color;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
}

@mixin primary-link-button {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: $primary-color;
}
