$dark-background-color: #121E24;
$dark-background-color-2: #162228;

$dark-gray-background-color: #455A64;
$light-dark-background-color: #263238;

$text-font-family: Montserrat, sans-serif;

$button-font-size: 14px;
$button-line-height: 17px;
$button-font-family: Montserrat, sans-serif;

$big-text-font-size: 72px;
$big-text-line-height: 98px;
$input-feedback-font-size: 15px;

$white: white;
$primary-color: #78BE21;
$secondary-color: #7766BB;
$action-color: #EC407A;
$muted-text-color: #90A4AE;
$red-alert-color: #D32F2F;
$dark-gray-2-color: #90A4AE;
$yellow-color: #F2C94C;
