@import '../../sass/base';

.otp-form.container {
  width: 24.375rem;
  position: relative;
  background-color: $light-dark-background-color;
  border-radius: 30px;
  padding: 0;
  padding-right: 1rem;
  @include phone {
    width: 100%;
    margin: auto;
    background: none;
  }

  .container {
    width: 100%;
    padding: 0;
  }

  .form-label {
    font-size: 0.75rem;
    line-height: 0.875rem;

    @include white-primary-label;
  }

  .otp-form-form {
    padding: 3.125rem;

    @include phone {
      padding: 0;
    }

    .form-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin: 0 0 1.25rem 0;

      input.form-control {
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;

        @include dark-gray-bordered-input;
      }

      .otp-form-input-error {
        @include input-with-error;
      }

      .otp-form-input-error-message {
        @include error-feedback-label;
      }
    }

    .otp-form-form-passcode-feedback-label {
      text-align: center;

      @include red-primary-label;
    }
  }

  .otp-form-form-cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0 0 0;

    .btn-link {
      padding: 0;
      font-size: 0.75rem;
      line-height: 0.938rem;

      @include primary-link-button;
    }
  }

  .otp-form-form-submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5rem 0 0 0;

    .container {
      padding: 0;
    }

    .otp-form-form-submit-container-submit-button {
      width: 95px;
      height: 40px;
      font-size: 0.875rem;
      line-height: 1.063rem;

      @include primary-button;

      @include phone {
        width: 100%;
        padding: 0;
      }
    }
  }
}
