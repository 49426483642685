@import '../../sass/base';

.dealme-users-list {
      
  @include white-primary-label;
  
  .dealme-users-list-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    .dealme-users-list-row{
      font-size: 14px;
      line-height: 17px;
      background: $dark-background-color-2;
      letter-spacing: 0.3px;
      height: 40px;
      overflow: hidden;
    }

    tr{
      height: 50px;
      &:first-child{
        cursor: pointer;
        #delete{
          cursor: auto;
        }
      }

      th{
        font-size: 14px;
        line-height: 17px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        // padding-top: 0.6rem;
        // padding-bottom: 0.6rem;
        // max-width: 15%;
        // min-width: 114px;
        background-color: $action-color;
      }
      th:first-child {
        padding-left: 1rem;
        border-radius: 0.5rem 0 0 0;
        width: 30%;
      }
      th:nth-child(2) {
        width: 38%;
      }
      th:nth-child(3) {
        width: 15%;
      }
      th:nth-child(4) {
        width: 11%;
      }
      th:nth-child(5) {
        border-radius: 0 0.5rem 0 0;
        width: 6%;
      }
      td{
        // border-radius: 10px;
        // padding-top: 5px;
        // padding-bottom: 5px;
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: 15%;

        .dealme-users-list-status-0{
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          background-color: $action-color;
          border-radius: 5px;
          height: 25px;
          width: 80px;
          padding: 5px 10px;
          text-align: center;
        }
        .dealme-users-list-status-1{
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          background-color: $primary-color;
          border-radius: 5px;
          height: 25px;
          width: 88px;
          padding: 5px 10px;
          text-align: center;
        }
        .dealme-users-list-status-2{
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          background-color: $yellow-color;
          border-radius: 5px;
          height: 25px;
          width: 88px;
          padding: 5px 10px;
          text-align: center;
        }
        .dealme-users-list-status-3{
          font-size: 12px;
          line-height: 15px;
          font-weight: bold;
          background-color: $red-alert-color;
          border-radius: 5px;
          height: 25px;
          width: 88px;
          padding: 5px 10px;
          text-align: center;
        }

        .dealme-users-list-close-button{
          img{
            width: 15px;
          }
          background: $dark-background-color-2;
          padding-inline: 10px;
          border: none;
        }
      }

      td:first-child{
        padding-left: 1rem;
      }
      td:last-child{
        padding-right: 1rem;
      }

    }
  }

  .pagination {
    .page-link {
      width: 44px;
      height: 44px;
      background-color: inherit;
      font-family: Montserrat;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      margin-right: 40px;
      border: none;
      padding: 10px 18px;
      border-radius: 5px;
      
      &.active {
        background-color: $primary-color;
      }
    }
    select option {
      background-color: $primary-color;
    }
  }
  .show-selector-container {
    font-size: 20px;
    line-height: 23px;
    label select {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .mobile-home{
    color: white;
  
    > div {
      border-radius: 10px;
      overflow: hidden;
  
      > div {
        height: 53px;
        border-bottom: solid 1px $muted-text-color;
        background-color: #212D33;
  
        &.read-status{
          height: 5px;
          background-color: $dark-gray-2-color;

          &.status-0{
            background-color: $action-color;
          }
          &.status-1{
            background-color: $primary-color;
          }
          &.status-2{
            background-color: $yellow-color;
          }
          &.status-3{
            background-color: $red-alert-color;
          }
        }
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

}


