@keyframes shake {
  0% { transform: rotate(0deg); }
  8.0% { transform: rotate(0deg); }
  12.0% { transform: rotate(42deg); }
  16.0% { transform: rotate(-35deg); }
  20.0% { transform: rotate(0deg); }
  23.0% { transform: rotate(28deg); }
  26.0% { transform: rotate(-20deg); }
  29.0% { transform: rotate(0deg); }
  31.0% { transform: rotate(16deg); }
  33.0% { transform: rotate(-12deg); }
  35.0% { transform: rotate(0deg); }
  37.0% { transform: rotate(-6deg); }
  39.0% { transform: rotate(0deg); }
}

.animate-shake {
  animation: shake 3500ms infinite;
}
