@import '../../../sass/variables';

.dealme-stepper-dots {
  text-align: center;

  input{
      width: 15px;
      height: 15px;
      margin: 10px;
  }
  input[type='radio']:after {
    left: 0px;
    top: -2px;
    width: 16px;
    height: 16px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
      background: $light-dark-background-color;
      border-radius: 50%;
      border: none;
    }
    input[type='radio']:checked:after {
      left: 0px;
      top: -2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: relative;
      background: $primary-color;
      content: '';
      display: inline-block;
      visibility: visible;
    }

}
