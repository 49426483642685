@import '../../sass/base';

.filters {
  input, select {
    width: 280px;
    margin-right: 20px;
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    border: none;
    color: white;
    border-radius: 0.625rem;
    background: #455A64;
    height: 32px;
    &.date{
      width: 180px;
    }
    &::-webkit-input-placeholder {
      font-size: 12px;
    }
    option {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
      color: #FFFFFF;
      margin: 0px 10px;
    }
  }
  select {
    display: block;
  }
  label span{
    display: block;
    margin-bottom: 1rem;
    font-family: Montserrat;
    color: white;
    font-size: 12px;
    line-height: 14px
  }
  .form-control:focus {
    background: #455A64;
    color: white;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #455A64 inset;
  color: white!important;
  transition: background-color 600000s 0s, color 600000s 0s;
}
input:-internal-autofill-selected {
  background: #455A64!important;
  color: white!important;
}
.filters-download-button{
  background: #78BE21!important;
}