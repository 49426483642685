@import '../_variables.scss';

@mixin white-primary-label {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: white;
}

@mixin red-primary-label {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: $red-alert-color;
}
