@import '../../sass/base';

.form-group {
    margin-bottom: 1.25rem;

    input.form-control {
      margin-right: -22px;

      @include dark-gray-bordered-input;
    }

    .dealme-manager-modal-form-input-error {
      @include input-with-error;
    }

    svg {
      z-index: 999;
      right: 11px;
      position: relative;
    }

    svg:hover {
      cursor: pointer;
    }

    .dealme-manager-modal-form-input-error-message {
      top: 0.625rem;
      line-height: 14px;
      position: relative;
      @include error-feedback-label;
    }

    textarea , textarea:focus{
      @include dark-gray-bordered-textarea;
    }
  }

  .dealme-manager-modal-form-phone-form-group {
    .form-group {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 0;
      justify-content: space-between;

      .dealme-dark-gray-bordered-select__control {
        width: 100px;
      }

      .dealme-dark-gray-bordered-select__menu {
        width: 100px;
      }

      .dealme-dark-gray-bordered-select__single-value {
        color: white;
        font-size: 0.75rem;
        line-height: 0.875rem;
      }

      .dealme-dark-gray-bordered-select__input {
        color: white;
      }

      .dealme-dark-gray-bordered-select__option {
        font-size: 0.75rem;
        line-height: 0.875rem;
      }

      :nth-child(1) {
        padding-right: 0.313rem;
      }

      :nth-child(2) {
        margin-right: 0px;
      }
    }
  }

  .form-label {
    font-size: 12px;
    line-height: 14px;

    @include white-primary-label;
  }
.dealme-manager-modal-row-container{
    display:flex;
}
.dealme-manager-modal-submit-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #78BE21!important;
  border: none!important;
}
.dealme-manager-modal-submit-button-container{
  margin-top: 40px;
  display:flex;
  justify-content: center;
  .btn {
    padding: 10px 30px;
    border-radius: 10px;
    font-weight: bold;
  }
}