@import '../../sass/base';

.input-filter{

  input.form-control {
    width: 100%;
    width: 248px;
    border: none;
    color: white;
    border-radius: 0.625rem;
    background: #455A64;
    
    // @media (min-width: 992px) {
    //   width: 18rem;
    // }
  }

  img{
    position: absolute;
    left: 266px;
    width: 20px;
    top: 8px;
    @media (max-width: 992px) {
      left: 235px;
    }
  }


  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }

}